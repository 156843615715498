<template>
  <van-list
    v-model="loading"
    :finished="finished"
    :immediate-check="false"
    @load="onLoad"
  >
    <van-cell
      v-for="(item, index) in list"
      :key="index"
      :title="item.productName"
    >
      <template #label>
        <div class="f-cb">
          <div>SKU{{ $t('colon') + item.productCode }}</div>
          <div>{{ $t('deviceCode') + $t('colon') + item.deviceCode }}</div>
        </div>
        <div class="f-cb">
          <div>
            {{ $t('channelLabelCode') + $t('colon') + item.channelLabelCode }}
          </div>
          <div>{{ $t('number') + $t('colon') + item.tradeNum }}</div>
        </div>
      </template>
    </van-cell>
  </van-list>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      list: [],
      loading: false,
      finished: false,
    }
  },
  created() {
    this.onLoad()
  },
  methods: {
    async onLoad() {
      // 异步更新数据
      this.loading = true
      const { page } = this
      // const { vmCodes } = this.$route.params
      const vmCodes = JSON.parse(sessionStorage.getItem('vmCodes'))
      const { data } = await this.$api.listTradeAdditionalApplet({
        vmCodes,
        page,
      })
      // 加载状态结束
      this.loading = false
      const {
        rows,
        page: { totalPage },
      } = data
      console.log('listTradeAdditionalApplet===>', rows)
      this.list = this.list.concat(rows)
      // 数据全部加载完成
      if (totalPage === page|| totalPage === 0) {
        this.finished = true
      } else {
        this.page++
      }
    },
  },
}
</script>